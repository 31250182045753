<template>
  <div class="park-enterprise-dialog">
    <div class="scroll-view">
      <el-form
        :model="ruleForm"
        :rules="rules"
        label-position="top"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row :gutter="48">
          <el-col :span="12">
            <el-form-item label="企业名称" prop="enterpriseName">
              <el-input
                maxlength="50"
                :disabled="currentState === 'view'"
                v-model="ruleForm.enterpriseName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="phone">
              <el-input
                maxlength="20"
                :disabled="currentState === 'view'"
                v-model="ruleForm.phone"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="行业类别" prop="industryType">
              <el-select
                @change="indestryTypeChange"
                :disabled="currentState === 'view'"
                style="width: 100%"
                v-model="ruleForm.industryType"
              >
                <el-option
                  v-for="(item, index) in industryTypeList"
                  :value="item.value"
                  :label="item.label"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="行业子类别" prop="industrySubtype">
              <el-select
                :disabled="currentState === 'view'"
                style="width: 100%"
                v-model="ruleForm.industrySubtype"
              >
                <el-option
                  v-for="(item, index) in industrySubtypeList"
                  :value="item.value"
                  :label="item.label"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="营业周" prop="businessWeekStart">
              <div class="form-item-line">
                <el-select
                  :disabled="currentState === 'view'"
                  style="width: 50%"
                  clearable
                  v-model="ruleForm.businessWeekStart"
                >
                  <el-option
                    v-for="(item, index) in weekList"
                    :value="item.value"
                    :label="item.label"
                    :key="index"
                  ></el-option>
                </el-select>
                <span class="split-span"> - </span>
                <el-select
                  clearable
                  :disabled="currentState === 'view'"
                  style="width: 50%"
                  v-model="ruleForm.businessWeekEnd"
                >
                  <el-option
                    v-for="(item, index) in weekList"
                    :value="item.value"
                    :label="item.label"
                    :key="index"
                  ></el-option>
                </el-select>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="营业时间" prop="businessTiem">
              <div class="form-item-line">
                <el-time-picker
                  :disabled="currentState === 'view'"
                  style="width: 50%"
                  v-model="ruleForm.businessTiem"
                  placeholder="开始时间"
                  format="HH:mm"
                  value-format="HH:mm"
                >
                </el-time-picker>
                <span class="split-span"> - </span>
                <el-time-picker
                  :disabled="currentState === 'view'"
                  style="width: 50%"
                  v-model="ruleForm.businessTiemEnd"
                  placeholder="结束时间"
                  format="HH:mm"
                  value-format="HH:mm"
                >
                </el-time-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业位置" prop="enterpriseAddress">
              <div class="form-item-line">
                <el-input
                  style="width: 25%"
                  :disabled="currentState === 'view'"
                  v-model="ruleForm.enterpriseAddressBuild"
                  maxlength="3"
                ></el-input>
                栋
                <el-input
                  style="width: 25%"
                  :disabled="currentState === 'view'"
                  v-model="ruleForm.enterpriseAddressLayer"
                  maxlength="3"
                ></el-input>
                层
                <el-input
                  style="width: 25%"
                  :disabled="currentState === 'view'"
                  v-model="ruleForm.enterpriseAddressNumber"
                  maxlength="3"
                ></el-input>
                号
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-loading="uploadLoading">
            <el-form-item
              label="企业LOGO"
              prop="photoIdListId"
              class="auto-height-item"
            >
              <div class="form-image-line">
                <el-upload
                  :disabled="currentState === 'view'"
                  class="avatar-uploader"
                  :action="uploadPath"
                  :with-credentials="true"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="imageUrl"
                    @click.stop="handlePictureCardPreview(imageUrl)"
                    :src="imageUrl"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <i
                  class="el-icon-delete"
                  @click="deleteEnterpriseLogo"
                  v-show="
                    currentState !== 'view' && ruleForm.photoIdListId.length > 0
                  "
                ></i>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="企业介绍"
              prop="enterpriseExplain"
              class="auto-height-item"
            >
              <el-input
                :disabled="currentState === 'view'"
                type="textarea"
                :rows="2"
                maxlength="500"
                placeholder="请输入企业介绍"
                v-model="ruleForm.enterpriseExplain"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-divider content-position="center" v-if="projectList.length > 0"
        >企业项目信息</el-divider
      >
      <div
        class="project-card"
        v-for="(item, index) in projectList"
        :key="index"
      >
        <el-form :model="item" label-position="top" label-width="100px">
          <el-row :gutter="48">
            <el-col :span="24">
              <el-form-item
                label="项目名称"
                prop="projectName"
                class="project-name-item"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="删除该项目"
                  placement="bottom-end"
                >
                  <i
                    class="el-icon-circle-close project-delete-icon"
                    v-show="currentState !== 'view'"
                    @click="deleteProject(index)"
                  ></i>
                </el-tooltip>
                <el-input
                  :disabled="currentState === 'view'"
                  maxlength="20"
                  v-model="item.projectName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-loading="item.uploadLoading">
              <el-form-item
                label="项目图片"
                prop="photoIdListId"
                class="auto-height-item"
              >
                <div class="upload-container">
                  <el-upload
                    :disabled="currentState === 'view'"
                    class="avatar-uploader-project"
                    :action="uploadPath"
                    :with-credentials="true"
                    :show-file-list="false"
                    :on-success="
                      (response, file, fileList) =>
                        handleAvatarSuccess(response, file, fileList, index)
                    "
                    :before-upload="(file) => beforeAvatarUpload(file, index)"
                  >
                    <img
                      v-if="item.imageUrl"
                      @click.stop="handlePictureCardPreview(item.imageUrl)"
                      :src="item.imageUrl"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <div class="tips-container">
                    <div>
                      <div>
                        温馨提示：最多上传1张，图片大小不能超过1M，图片格式为jpg，png。
                      </div>
                      <div>图片尺寸212*112</div>
                    </div>
                    <div
                      v-show="
                        currentState !== 'view' && item.photoIdListId.length > 0
                      "
                      class="delete-icon"
                      @click="deleteProjectImageHandler(index)"
                    >
                      <i class="el-icon-delete"></i>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                label="项目介绍"
                prop="projectExplain"
                class="auto-height-item"
              >
                <el-input
                  :disabled="currentState === 'view'"
                  type="textarea"
                  :rows="2"
                  maxlength="500"
                  placeholder="请输入项目介绍"
                  v-model="item.projectExplain"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div
        class="project-add-line"
        :style="{ cursor: projectList.length < 10 ? 'pointer' : 'not-allowed' }"
        @click="addProject"
        v-show="currentState !== 'view'"
      >
        <el-tooltip
          :disabled="projectList.length < 10"
          class="item"
          effect="dark"
          content="项目数量最多添加10个"
          placement="top"
        >
          <span>
            <i class="el-icon-plus"></i>
            <span> 添加项目 </span>
          </span>
        </el-tooltip>
      </div>
    </div>
    <el-row style="margin: 10px 0; text-align: right">
      <span v-if="currentState === 'edit' || currentState === 'add'">
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确定</el-button
        >
        <el-button @click="cancelHandler">取消</el-button>
      </span>
      <el-button
        v-if="currentState === 'view'"
        @click="currentState = 'edit'"
        type="primary"
        >编辑</el-button
      >
    </el-row>
    <el-dialog :visible.sync="dialogVisible" :modal="false">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { envInfo } from "@/constants/envInfo";
import {
  addParkEnterprise,
  getParkEnterpriseDetail,
  updateParkEnterprise,
} from "@/api/ruge/gsPark/customerService/parkEnterprise";

export default {
  name: "parkEnterpriseDialog",
  props: {
    industryType: {
      type: Array,
      required: true,
    },
    industrySubtype: {
      type: Array,
      required: true,
    },
    dialogStatus: {
      type: String,
      required: true,
    },
    id: {
      required: false,
    },
  },
  watch: {
    dialogStatus: {
      handler(val) {
        this.currentState = val;
      },
      immediate: true,
    },
    id: {
      handler(id) {
        if (!id) return;
        this.getEnterpriseDetail(id);
      },
      immediate: true,
    },
  },
  data() {
    const businessTiemValid = (rule, value, callback) => {
      const { businessTiem, businessTiemEnd } = this.ruleForm;
      if (businessTiem && businessTiemEnd) {
        if (businessTiemEnd <= businessTiem) {
          callback(new Error("结束时间必须早于开始时间"));
        }
      }
      if (businessTiem && !businessTiemEnd) {
        callback(new Error("请选择营业结束时间"));
      }
      if (!businessTiem && businessTiemEnd) {
        callback(new Error("请选择营业开始时间"));
      }
      callback();
    };
    const addressValid = (rule, value, callback) => {
      const Reg = /^[0-9a-zA-Z]+$/;
      const {
        enterpriseAddressBuild,
        enterpriseAddressNumber,
        enterpriseAddressLayer,
      } = { ...this.ruleForm };
      if (
        !enterpriseAddressBuild ||
        !enterpriseAddressNumber ||
        !enterpriseAddressLayer
      ) {
        callback(new Error("企业位置为必填"));
      } else if (
        !Reg.test(enterpriseAddressBuild) ||
        !Reg.test(enterpriseAddressNumber) ||
        !Reg.test(enterpriseAddressLayer)
      ) {
        callback(new Error("企业位置的栋、层、号只能由数字和英文组成"));
      } else {
        callback();
      }
    };
    const phoneValid = (rule, value, callback) => {
      if (!value) callback();
      // eslint-disable-next-line
      const Reg = /^[0-9\-]+$/;
      if (!Reg.test(value) || value.length > 15) {
        callback(new Error("联系电话仅支持15位以内的阿拉伯数字，横杠"));
      } else {
        callback();
      }
    };
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      projectList: [],
      uploadLoading: false,
      uploadPath:
        envInfo.bgApp.archivePath +
        "/param/archive/upload?ulType=DefaultUpload&scopeType=ALL",
      imageUrl: null,
      currentState: "add",
      industryTypeList: this.industryType,
      industrySubtypeList: [],
      weekList: [
        { value: "1", label: "周一" },
        { value: "2", label: "周二" },
        { value: "3", label: "周三" },
        { value: "4", label: "周四" },
        { value: "5", label: "周五" },
        { value: "6", label: "周六" },
        { value: "7", label: "周日" },
      ],
      ruleForm: {
        enterpriseName: null,
        phone: null,
        industryType: null,
        industrySubtype: null,
        photoIdListId: [],
        businessWeekStart: null,
        businessWeekEnd: null,
        businessTiem: null,
        businessTiemEnd: null,
        enterpriseExplain: null,
        enterpriseAddressBuild: "",
        enterpriseAddressLayer: "",
        enterpriseAddressNumber: "",
      },
      rules: {
        enterpriseName: [
          { required: true, message: "企业名称为必填项", trigger: "blur" },
        ],
        industryType: [
          {
            required: true,
            message: "行业类别为必填项",
            trigger: ["blur", "change"],
          },
        ],
        industrySubtype: [
          {
            required: true,
            message: "行业子类别为必填项",
            trigger: ["blur", "change"],
          },
        ],
        enterpriseAddress: [
          { required: true, validator: addressValid, trigger: "change" },
        ],
        phone: [{ required: false, validator: phoneValid, trigger: "change" }],
        businessTiem: [
          { required: false, validator: businessTiemValid, trigger: "change" },
        ],
      },
    };
  },
  methods: {
    handlePictureCardPreview(imageUrl) {
      this.dialogImageUrl = imageUrl;
      this.dialogVisible = true;
    },
    deleteProject(index) {
      this.projectList.splice(index, 1);
    },
    deleteEnterpriseLogo() {
      this.imageUrl = "";
      this.ruleForm.photoIdListId = [];
    },
    deleteProjectImageHandler(index) {
      this.projectList[index].imageUrl = "";
      this.projectList[index].photoIdListId = [];
    },
    getEnterpriseDetail(id) {
      getParkEnterpriseDetail({ id }).then((res) => {
        this.ruleForm.enterpriseName = res.data.enterpriseName;
        this.ruleForm.phone = res.data.phone;
        this.ruleForm.industryType = res.data.industryType;
        this.ruleForm.industrySubtype = res.data.industrySubtype;
        this.ruleForm.photoIdListId = res.data.photoIdListId;
        this.ruleForm.businessWeekStart = res.data.businessWeekStart;
        this.ruleForm.businessWeekEnd = res.data.businessWeekEnd;
        this.ruleForm.businessTiem = res.data.businessTiem;
        this.ruleForm.businessTiemEnd = res.data.businessTiemEnd;
        this.ruleForm.enterpriseAddressBuild = res.data.enterpriseAddressBuild;
        this.ruleForm.enterpriseAddressLayer = res.data.enterpriseAddressLayer;
        this.ruleForm.enterpriseExplain = res.data.enterpriseExplain;
        this.ruleForm.enterpriseAddressNumber =
          res.data.enterpriseAddressNumber;
        this.imageUrl = res.data.photoIdListId[0]
          ? envInfo.bgApp.archivePath +
            "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
            res.data.photoIdListId[0]
          : "";
        this.projectList = res.data.projectVOS || [];
        this.projectList.forEach((item, index) => {
          this.projectList[index].imageUrl = item.photoIdListId[0]
            ? envInfo.bgApp.archivePath +
              "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
              item.photoIdListId[0]
            : "";
        });
        this.indestryTypeChange(res.data.industryType, true);
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitHandler();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    parseParams(params) {
      params.projectVOS = this.projectList
        .filter(
          (item) =>
            item.projectName ||
            item.photoIdListId.length > 0 ||
            item.projectExplain
        )
        .map((item) => {
          return {
            projectName: item.projectName,
            photoIdListId: item.photoIdListId,
            projectExplain: item.projectExplain,
            enterpriseId: this.id,
            id: item.id,
          };
        });
      if (this.currentState === "edit") {
        params.id = this.id;
      }
      return params;
    },
    submitHandler() {
      let params = { ...this.ruleForm };
      params = this.parseParams(params);
      console.log("params", params);
      if (this.currentState === "add") {
        addParkEnterprise(params).then((res) => {
          if (res.code === 200) {
            this.$message.success("新增入园企业成功");
            this.$emit("close", true);
          } else {
            this.$message.warning("新增入园企业失败！");
          }
        });
      } else {
        updateParkEnterprise(params).then((res) => {
          if (res.code === 200) {
            this.$message.success("编辑入园企业成功");
            this.$emit("close", true);
          } else {
            this.$message.warning("编辑入园企业失败！");
          }
        });
      }
    },
    indestryTypeChange(type, clearFlag) {
      !clearFlag && (this.ruleForm.industrySubtype = "");
      this.industrySubtypeList = this.industrySubtype.filter((item) =>
        item.value.startsWith(type)
      );
    },
    cancelHandler() {
      this.$emit("close");
    },
    addProject() {
      if (this.projectList.length >= 10) return;
      this.projectList.push({
        projectName: "",
        photoIdListId: [],
        projectExplain: "",
        enterpriseId: "",
        id: "",
        imageUrl: null,
        uploadLoading: false,
      });
    },
    handleAvatarSuccess(files, file, fileList, index) {
      console.log("indexindexindexindex", index);
      if (typeof index === "number") {
        this.projectList[index].photoIdListId = [files[0].fileId];
        this.projectList[index].imageUrl =
          envInfo.bgApp.archivePath +
          "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
          files[0].fileId;
        this.projectList[index].uploadLoading = false;
      } else {
        this.ruleForm.photoIdListId = [files[0].fileId];
        this.imageUrl =
          envInfo.bgApp.archivePath +
          "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
          files[0].fileId;
        this.uploadLoading = false;
      }
    },
    beforeAvatarUpload(file, index) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 1;

      if (!isJPG && !isPNG) {
        this.$message.error("上传图片只能是 JPG,PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }
      const returnFlag = (isJPG || isPNG) && isLt2M;
      if (typeof index === "number") {
        this.projectList[index].uploadLoading = returnFlag;
      } else {
        this.uploadLoading = returnFlag;
      }
      return returnFlag;
    },
  },
};
</script>

<style scoped lang="less">
.park-enterprise-dialog {
  .scroll-view {
    // 给滚动条让出位置
    margin-right: -30px;
    padding-right: 30px;
  }
  .form-item-line {
    display: flex;
    justify-content: space-between;
    .split-span {
      width: 10px;
      text-align: center;
    }
  }
  .form-image-line {
    display: flex;
    .el-icon-delete {
      color: #ff0000;
      cursor: pointer;
      margin-left: 10px;
    }
  }
  .project-add-line {
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 1px dotted #ccc;
    cursor: pointer;
  }
  .avatar-uploader {
    border: 1px dotted #ccc;
    height: 80px;
    width: 80px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
  }
  .avatar {
    width: 80px;
    height: 80px;
    display: block;
  }
  .upload-container {
    position: relative;
    display: flex;
    .tips-container {
      margin-left: 15px;
      font-size: 12px;
      & > div {
        height: 20px;
        line-height: 20px;
      }
      .delete-icon {
        position: absolute;
        bottom: 0;
        font-size: 14px;
        color: #ff0000;
        cursor: pointer;
      }
    }
  }
  .project-name-item {
    position: relative;
    .project-delete-icon {
      color: #ff0000;
      cursor: pointer;
      position: absolute;
      right: -25px;
      top: 6px;
      font-size: 20px;
    }
  }
  .avatar-uploader-project {
    border: 1px dotted #ccc;
    height: 178px;
    width: 178px;
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      &:hover {
        border-color: #409eff;
      }
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  }
  ::v-deep .el-form-item__content {
    height: 36px;
  }
  ::v-deep .el-divider__text {
    font-weight: 500;
    font-size: 18px;
  }
  .auto-height-item {
    ::v-deep .el-form-item__content {
      height: auto;
    }
  }
}
</style>