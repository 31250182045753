<template>
  <div class="app-container park-notice-comp">
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="addNewHandler">{{
          this.$t("commons.addNew")
        }}</el-button>
        <el-tooltip
          class="item"
          effect="dark"
          :disabled="parkEnterpriseObj.selectionList.length > 0"
          :content="$t('commons.multipleSelectionOne')"
          placement="top"
        >
          <el-button
            type="danger"
            @click="batchDeleteHandler"
            :class="
              parkEnterpriseObj.selectionList.length === 0 &&
              'not-allowed-button'
            "
            icon="el-icon-delete"
            plain
            >{{ $t("commons.delete") }}
          </el-button>
        </el-tooltip>
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        <i class="iconfont icon-loudoutu"></i>
        筛选
      </div>
    </div>
    <div>
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>

    <!-- 新增dialog -->
    <el-dialog
      :visible.sync="parkEnterpriseObj.dialogVisible"
      :before-close="handleClose"
      width="900px"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <template slot="title">
        <span>{{ parkEnterpriseObj.dialogTitle }}</span>
      </template>
      <parkEnterpriseDialog
        v-if="parkEnterpriseObj.dialogVisible"
        :dialogStatus="parkEnterpriseObj.dialogStatus"
        :industryType="industryType"
        :industrySubtype="industrySubtype"
        :id="parkEnterpriseObj.id"
        @close="handleClose"
      />
    </el-dialog>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import { findLookupItem } from "@/api/ruge/lookup/lookup";
import parkEnterpriseDialog from "./parkEnterpriseDialog";
import { envInfo } from "@/constants/envInfo";
import {
  getParkEnterpriseList,
  deleteParkEnterprise,
} from "@/api/ruge/gsPark/customerService/parkEnterprise";

export default {
  components: {
    parkEnterpriseDialog,
    finalTable,
  },
  data() {
    return {
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: true,
          width: "18",
        },
        header: [
          { prop: "enterpriseName", label: "企业名称", width: "" },
          { prop: "phone", label: "联系电话", width: "" },
          { prop: "industryType", label: "行业类别", width: "" },
          { prop: "industrySubtype", label: "行业子类", width: "" },
          { prop: "businessWeek", label: "营业周期", width: "" },
          { prop: "businessTiem", label: "营业时间", width: "" },
          { prop: "enterpriseAddress", label: "企业位置", width: "" },
          { prop: "countProject", label: "项目数量", width: "" },
          { prop: "operation", label: "操作", width: "145" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          enterpriseName: {
            type: "input",
            label: "企业名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入企业名称",
            prefixIcon: "el-icon-search",
          },
          industryType: {
            type: "selector",
            label: "行业类别",
            value: "",
            placeholder: "请选择行业类别",
            actionType: "goSearch",
            optionList: [],
          },
          industrySubtype: {
            type: "selector",
            label: "行业子类",
            value: "",
            placeholder: "请选择行业子类",
            actionType: "goSearch",
            optionList: [],
          },
          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          businessWeek: {
            type: "joinList",
            joinSymbol: "~",
            joinList: ["businessWeekStart", "businessWeekEnd"],
          },
          businessTiem: {
            type: "joinList",
            joinSymbol: "~",
            joinList: ["businessTiem", "businessTiemEnd"],
          },
          enterpriseAddress: {
            type: "joinSymbolList",
            joinSymbol: "-",
            joinList: [
              "enterpriseAddressBuild",
              "enterpriseAddressLayer",
              "enterpriseAddressNumber",
            ],
            symbolList: ["栋", "层", "号"],
          },
          industryType: {
            type: "enumerationColumn",
            emuList: {},
          },
          industrySubtype: {
            type: "enumerationColumn",
            emuList: {},
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "详情",
              },

              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          enterpriseName: {
            inline: true,
            value: "",
          },
          industryType: {
            inline: true,
            value: "",
          },
          industrySubtype: {
            inline: true,
            value: "",
          },
          enterpriseAddressBuild: {
            type: "input",
            label: "楼栋",
            placeholder: "请输入楼栋",
            value: "",
          },
          enterpriseAddressLayer: {
            type: "input",
            label: "楼层",
            placeholder: "请输入楼层",
            value: "",
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      parkEnterpriseObj: {
        selectionList: [],
        dialogVisible: false,
        dialogStatus: "add",
        dialogTitle: "新增入园企业",
        loading: false,
        listQuery: {
          current: 1,
          rowCount: 10,
          enterpriseName: null,
          industryType: null,
          industrySubtype: null,
          enterpriseAddressBuild: null,
          enterpriseAddressLayer: null,
        },
      },
      industryType: [],
      industrySubtype: [],
      weekObj: {
        1: "周一",
        2: "周二",
        3: "周三",
        4: "周四",
        5: "周五",
        6: "周六",
        7: "周日",
      },
    };
  },
  watch: {
    parkEnterpriseObj: {
      handler(val) {
        console.log("val", val);
        this.$refs.finalTableRef.loadingToggle(val.loading);
      },
      deep: true,
    },
  },
  created() {
    this.dataInit();
  },
  methods: {
    getTableList() {
      this.parkEnterpriseObj.loading = true;
      const params = { ...this.parkEnterpriseObj.listQuery };
      getParkEnterpriseList(params)
        .then((res) => {
          // 处理营业周期显示问题
          res.data.rows.forEach((item) => {
            item.businessWeekEnd = this.weekObj[item.businessWeekEnd];
            item.businessWeekStart = this.weekObj[item.businessWeekStart];
          });
          this.dataset.pageVO.total = res.data.total;
          this.dataset.tableData = res.data.rows;
          this.parkEnterpriseObj.loading = false;
        })
        .catch(() => {
          this.parkEnterpriseObj.loading = false;
        });
    },
    handleClose(freshFlag) {
      this.parkEnterpriseObj.dialogVisible = false;
      freshFlag && this.getTableList();
    },
    addNewHandler() {
      this.parkEnterpriseObj.dialogStatus = "add";
      this.parkEnterpriseObj.id = null;
      this.parkEnterpriseObj.dialogTitle = "新增入园企业";
      this.parkEnterpriseObj.dialogVisible = true;
    },
    dataInit() {
      this.getTableList();
      findLookupItem("CUST_TRADE_TYPE").then((row) => {
        const resultList = row;
        this.industryType = [];
        this.industrySubtype = [];
        let industryTypeObj = {};
        let industrySubTypeObj = {};
        resultList.forEach((item) => {
          let curObj = {
            value: item.itemCode,
            label: item.itemName,
          };
          if (item.itemCode.length === 1) {
            this.industryType.push(curObj);
            industryTypeObj[item.itemCode] = item.itemName;
          } else {
            this.industrySubtype.push(curObj);
            industrySubTypeObj[item.itemCode] = item.itemName;
          }
        });
        this.dataset.searchLineConfig.industryType.optionList =
          this.industryType;
        this.dataset.detailConfig.industryType.emuList = industryTypeObj;
        this.dataset.detailConfig.industrySubtype.emuList = industrySubTypeObj;
      });
    },
    batchUpdate(status) {
      if (this.parkEnterpriseObj.selectionList.length === 0) return;
      this.updateState(this.parkEnterpriseObj.selectionList, status);
    },
    updateState(datas, status) {
      const params = datas.map((item) => {
        return {
          id: item.id,
          status: status,
        };
      });
      updateServiceGuideState(params).then((res) => {
        if (res.code === 200) {
          this.$message.success("状态更新成功！");
          this.getTableList();
        } else {
          this.$message.warning("状态更新失败！");
        }
      });
    },
    batchDeleteHandler() {
      if (this.parkEnterpriseObj.selectionList.length === 0) return;
      this.deleteHandler(
        this.parkEnterpriseObj.selectionList.map((item) => item.id)
      );
    },
    singleDeleteHandler(row) {
      this.deleteHandler([row.id]);
    },
    deleteHandler(ids) {
      this.$confirm(
        "删除后将不可恢复，确定删除么？",
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.parkEnterpriseObj.loading = true;
          deleteParkEnterprise(ids)
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("删除成功！");
                this.getTableList();
              } else {
                this.$message.warning("删除失败！");
              }
              this.parkEnterpriseObj.loading = false;
            })
            .catch(() => {
              this.parkEnterpriseObj.loading = false;
            });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    downloadHandler({ id, fileId }) {
      //下载文件
      const url =
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fi=" +
        fileId;
      const asemName = "txt";
      const element = document.createElement("a");
      element.setAttribute("href", url);
      element.setAttribute("download", asemName);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      downloadServiceGuide({ id }).then(() => {
        this.getTableList();
      });
    },
    industryTypeDeal(datas) {
      if (datas.params.industryType || datas.params.industryType === "") {
        // 清空行业子类型

        datas.params.industrySubtype =
          datas.params.industrySubtype !== ""
            ? datas.params.industrySubtype
            : "";
        console.log("xxx111", JSON.parse(JSON.stringify(datas)));
        // 给子类型list赋值
        this.setSubTypeList(
          datas.params.industryType,
          datas.params.industrySubtype
        );
        console.log("xxx222", JSON.parse(JSON.stringify(datas)));
      }
      return datas;
    },
    setSubTypeList(industryType, industrySubtype) {
      let curSubLit = [];
      if (industryType) {
        curSubLit = this.industrySubtype.filter((item) =>
          item.value.startsWith(industryType)
        );
      }
      this.dataset.searchLineConfig.industrySubtype.value =
        industrySubtype || "";
      this.dataset.searchLineConfig.industrySubtype.optionList = curSubLit;
    },
    tableEventHandler(datas) {
      console.log("xxx000", JSON.parse(JSON.stringify(datas)));
      datas = JSON.parse(JSON.stringify(datas));
      if (datas.type === "goSearch") {
        datas = this.industryTypeDeal(datas);
        this.parkEnterpriseObj.listQuery = {
          ...this.parkEnterpriseObj.listQuery,
          ...datas.params,
        };
        this.parkEnterpriseObj.listQuery.current = 1;
        this.getTableList();
      } else if (datas.type === "selectorLinkage") {
        console.log("datas.params", datas.params);
        const {
          enterpriseAddressBuild,
          enterpriseAddressLayer,
          industrySubtype,
          enterpriseName,
          industryType,
        } = datas.params;
        if (industryType) {
          let curSubLit = [];
          if (industryType) {
            curSubLit = this.industrySubtype.filter((item) =>
              item.value.startsWith(industryType)
            );
          }
          console.log(
            "xxx1",
            curSubLit.map((item) => item.value)
          );
          console.log("xxx2", industrySubtype);
          this.dataset.advanceFilterConfig.industryType.value = industryType;
          this.dataset.searchLineConfig.industryType.value = industryType;
          this.dataset.advanceFilterConfig.industrySubtype.value = curSubLit
            .map((item) => item.value)
            .includes(industrySubtype)
            ? industrySubtype
            : "";
          this.dataset.advanceFilterConfig.enterpriseName.value =
            enterpriseName;
          this.dataset.advanceFilterConfig.enterpriseAddressBuild.value =
            enterpriseAddressBuild;
          this.dataset.advanceFilterConfig.enterpriseAddressLayer.value =
            enterpriseAddressLayer;
          this.dataset.searchLineConfig.industrySubtype.value = curSubLit
            .map((item) => item.value)
            .includes(industrySubtype)
            ? industrySubtype
            : "";
          this.dataset.searchLineConfig.industrySubtype.optionList = curSubLit;
        } else {
          this.dataset.searchLineConfig.industrySubtype.value = "";
          this.dataset.searchLineConfig.industrySubtype.optionList = [];
          this.dataset.advanceFilterConfig.industrySubtype.value = "";
          this.dataset.advanceFilterConfig.industryType.value = industryType;
          this.dataset.searchLineConfig.industryType.value = industryType;
        }
        // if (industryType && industrySubtype) {
        //   this.dataset.advanceFilterConfig.industrySubtype.value =
        //     industrySubtype;
        //   this.dataset.searchLineConfig.industrySubtype.value = industrySubtype;
        // }
      } else if (datas.type === "paginationChange") {
        this.parkEnterpriseObj.listQuery.current = datas.params.current.page;
        this.parkEnterpriseObj.listQuery.rowCount = datas.params.current.limit;
        this.getTableList();
      } else if (datas.type === "updateSelectionList") {
        this.parkEnterpriseObj.selectionList = datas.list.filter(
          (item) => item
        );
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "view":
            this.parkEnterpriseObj.dialogTitle = "入园企业详情";
            this.parkEnterpriseObj.dialogStatus = "view";
            this.parkEnterpriseObj.id = datas.row.id;
            this.parkEnterpriseObj.dialogVisible = true;
            break;
          case "delete":
            this.singleDeleteHandler(datas.row);
            break;
          case "edit":
            this.parkEnterpriseObj.dialogTitle = "编辑入园企业";
            this.parkEnterpriseObj.dialogStatus = "edit";
            this.parkEnterpriseObj.id = datas.row.id;
            this.parkEnterpriseObj.dialogVisible = true;
            break;
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.park-notice-comp {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
    .filter-button-open {
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #c6e2ff;
    }
    .filter-button-close {
      color: #2a4158;
      background: #ffffff;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
    .not-allowed-primary-button {
      cursor: not-allowed;
      color: #fff;
      background-color: #a0cfff;
      border-color: #a0cfff;
    }
  }
}
</style>